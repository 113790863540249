/* globals $ window gtag submitGoogleAnalyticsConversion setChatEvents launchChat loadChat */

import { Duxstrap } from './duxfront/duxstrap/duxstrap-core';
import { DuxstrapLite } from './duxfront/duxstrap/duxstrap-lite';
import { DuxstrapPlus } from './duxfront/duxstrap/duxstrap-plus';
import { DuxstrapVideo } from './duxfront/duxstrap/duxstrap-video';

Duxstrap.init();
DuxstrapLite.init();
DuxstrapPlus.init();
DuxstrapVideo.init();

if (typeof global.contact_page === 'undefined') {
  global.contact_page = false;
}

// SUBMIT GOOGLE ANALYTICS CONVERSION
global.submitGoogleAnalyticsConversion = function () {
  console.log('conversion!');
  gtag('event', 'click', {
    event_category: 'button',
    event_label: 'contact',
  });
  gtag('event', 'conversion', {
    'send_to': 'AW-790091113/Qx_eCM2n1_sBEOmq3_gC'
  });
};

// SUBMIT CONVERSION WHEN CHAT STARTS
global.setChatEvents = function () {
  global.HubSpotConversations.on('conversationStarted', () => {
    submitGoogleAnalyticsConversion();
  });
};

// LAUNCH CHAT MODAL
global.launchChat = function (e) {
  if (typeof e !== 'undefined') {
    e.preventDefault();
  }
  global.HubSpotConversations.widget.open();
};

// LOAD CHAT
global.loadChat = function () {
  if ($('.dashboard-body').length === 0) {
    if (window.contact_page) {
      const hubspotIframe = $('.hubspot-inline-chat iframe');
      window.hsConversationsSettings = {
        inlineEmbedSelector: '.hubspot-inline-chat',
      };
      if (hubspotIframe.length > 0) {
        hubspotIframe.addClass('d-none');
        setTimeout(() => {
          hubspotIframe.removeClass('d-none');
        }, 500);
      }
    }
    if (window.HubSpotConversations) {
      setChatEvents();
    } else {
      window.hsConversationsOnReady = [setChatEvents];
    }
  }
};

// ON LOAD
$(() => {
  $('#search-modal').on('shown.bs.modal', () => {
    $('#search_query').focus();
  });
  $('.search-suggestion').click(function (e) {
    e.preventDefault();
    $('#search_query').val($(this).attr('href'));
    $('#search_query').closest('form').submit();
  });
  $('.launch-chat').click(launchChat);
  $('.submit-conversion').click(submitGoogleAnalyticsConversion);
  if (window.contact_page) {
    loadChat();
  }
});
