/* globals $ */

import Swiper from './vendor/swiper.min';

export const DuxstrapPlus = {
  init() {
    DuxstrapPlus.Swiper();
  },

  Swiper() {
    $('.swiper-container').each(function (index) {
      const $this = $(this);

      $this.find('.swiper-pagination').addClass(`swiper-pagination-${index}`);
      $this.find('.swiper-button-next').addClass(`swiper-button-next-${index}`);
      $this.find('.swiper-button-prev').addClass(`swiper-button-prev-${index}`);

      const options = {
        parallax: true,
        speed: 1500,
        simulateTouch: false,
        effect: 'fade',
        pagination: {
          el: `.swiper-pagination-${index}`,
          clickable: true,
        },
        navigation: {
          nextEl: `.swiper-button-next-${index}`,
          prevEl: `.swiper-button-prev-${index}`,
        },
      };

      if ($this.hasClass('swiper-container-carousel')) {
        options.spaceBetween = 10;
        options.effect = 'slide';
        options.simulateTouch = true;
        options.slideToClickedSlide = true;
      }

      if ($this.hasClass('swiper-autoplay')) {
        options.autoplay = true;
        if ($this.attr('data-autoplay')) {
          options.autoplay = {
            delay: parseInt($this.attr('data-autoplay'), 10),
          };
        }
      }

      new Swiper($this, options);
    });

    $('.swiper-gallery').each(function () {
      const $this = $(this);

      const options = {
        effect: 'fade',
        speed: 1500,
        simulateTouch: false,
      };

      if ($this.hasClass('swiper-autoplay')) {
        options.autoplay = true;
        if ($this.attr('data-autoplay')) {
          options.autoplay = {
            delay: parseInt($this.attr('data-autoplay'), 10),
          };
        }
      }

      const galleryTop = new Swiper($this.find('.gallery-container'), options);

      const galleryThumbs = new Swiper($this.find('.gallery-thumbs'), {
        centeredSlides: true,
        slidesPerView: 6,
        speed: 1500,
        breakpoints: {
          1600: {
            slidesPerView: 5,
          },
          1200: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 2,
          },
          576: {
            slidesPerView: 2,
          },
        },
        slideToClickedSlide: true,
      });

      galleryTop.controller.control = galleryThumbs;
      galleryThumbs.controller.control = galleryTop;
    });
  },
};
